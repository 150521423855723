import { IMAGE_CONFIG } from "@angular/common";
import {
  provideHttpClient,
  withFetch,
  withInterceptors,
} from "@angular/common/http";
import { ApplicationConfig, provideZoneChangeDetection } from "@angular/core";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
  provideRouter,
  withComponentInputBinding,
  withInMemoryScrolling,
  withRouterConfig,
  withViewTransitions,
} from "@angular/router";
import { provideFastSVG } from "@push-based/ngx-fast-svg";
import { provideRoamEnvConfig } from "@roamstay/core";
import { provideUiTheme } from "@roamstay/ui";

import {
  MAT_ICON_DEFAULT_OPTIONS,
  MatIconDefaultOptions,
} from "@angular/material/icon";
import { environment } from "../environments/environment";
import { APP_ROUTES } from "./app.routes";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideAnimationsAsync("animations"),
    provideRoamEnvConfig(environment),
    provideUiTheme(),
    provideRouter(
      APP_ROUTES,
      // withDebugTracing(),
      withComponentInputBinding(),
      withViewTransitions({ skipInitialTransition: true }),
      withRouterConfig({ paramsInheritanceStrategy: "always" }),
      withInMemoryScrolling({ scrollPositionRestoration: "top" })
    ),
    provideHttpClient(
      withFetch(),
      withInterceptors([
        // TODO: later usage
      ])
    ),
    // NOTE: Do not need this for now...
    {
      provide: IMAGE_CONFIG,
      useValue: {
        disableImageSizeWarning: true,
        disableImageLazyLoadWarning: true,
      },
    },
    {
      provide: MAT_ICON_DEFAULT_OPTIONS,
      useValue: <MatIconDefaultOptions>{ fontSet: "material-symbols-rounded" },
    },
    provideFastSVG({
      url: (name: string) => `assets/icons/svg/${name}.svg`,
      defaultSize: "18",
    }),
  ],
};
