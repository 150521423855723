import { Routes } from "@angular/router";

export const APP_ROUTES: Routes = [
  {
    path: "hello-world",
    title: "Hello World",
    loadComponent: async () => {
      return (await import("./hello-world/hello-world.page")).HelloWorldPage;
    },
  },
  {
    path: "home",
    title: "Home",
    loadComponent: async () => {
      return (await import("./home/home.page")).HomePage;
    },
  },
  {
    path: "get-started",
    title: "Get Started",
    loadComponent: async () => {
      return (await import("./get-started/get-started.page")).GetStartedPage;
    },
  },
  {
    path: "role-sign-up/:id",
    title: "Role Sign Up",
    loadComponent: async () => {
      return (await import("./auth/+pages")).RoleSignUpPage;
    },
  },
  {
    path: "sign-in",
    title: "Sign In",
    loadComponent: async () => {
      return (await import("./auth/+pages")).SignInPage;
    },
  },
  {
    path: "association/:associationId",
    children: [
      {
        path: "join",
        title: "Association | Join",
        loadComponent: async () => {
          return (await import("./association/+page")).JoinAssociationPage;
        },
      },
      {
        path: "otp-verification",
        title: "Association | Verify OTP",
        loadComponent: async () => {
          return (await import("./association/+page")).AssociationOtpPage;
        },
      },
      {
        path: "document-verification",
        title: "Association | Verify Document",
        loadComponent: async () => {
          return (await import("./association/+page")).AssociationDocPage;
        },
      },
      {
        path: "submission-completed",
        title: "Association | Submission Completed",
        loadComponent: async () => {
          return (await import("./association/+page")).AssociationCompletedPage;
        },
      },
    ],
  },
  {
    path: "",
    pathMatch: "full",
    redirectTo: "home",
  },
];
