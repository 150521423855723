import { APP_INITIALIZER, Provider, inject } from "@angular/core";
import { UiThemeService } from "./theme.service";

export function provideUiTheme(): Provider {
  return {
    multi: true,
    provide: APP_INITIALIZER,
    useFactory: (service = inject(UiThemeService)) => {
      return () => {
        service.initialize();
      };
    },
  };
}
