import { RoamEnvConfig } from "@roamstay/core";

export const environment: RoamEnvConfig = {
  appId: "demo-dev",
  envName: "development",
  pmsApiUrl: 'https://devapi.roamstay.com/pms',
  dashboardUrl: "https://dev.app.roamstay.com",
  // dashboardUrl: 'http://localhost:4200',
  googleClientId: '879107131855-ajs4fsecjaoekdn2jpnbjqqbhqt2lro2.apps.googleusercontent.com',
  fileConfig: {
    apiUrl: "",
    cdnUrl: "",
  },
};
